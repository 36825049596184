<template>
  <div class="wrapper login-wrapper">
    <div class="container flex-col py-12 px-1">
      <h1 class="mb-2 self-center text-4xl">Kontakt</h1>
      <div class="flex mobile:flex-col flex-row mb-5">
        <contact-form
          header="Küsimused? Ettepanekud?"
          text="Võtke meiega ühendust, vastame Teile esimesel võimalusel."
          class="shadow p-5"
        ></contact-form>
        <div
          class="mobile:w-11/12 mobile:mx-auto w-4/12 flex-col flex py-2 mobile:px-0 px-8 mobile:border-0 justify-start"
        >
          <div class="contact-card">
            <a href="mailto:info@gewodo.com" class="w-full flex">
              <div class="contact-card-icon">
                <img src="/icons/contacts/email.svg" />
              </div>
              <div class="contact-card-content">
                info@gewodo.com
              </div>
            </a>
          </div>
          <div class="contact-card">
            <a href="tel:+37258416216" class="w-full flex">
              <div class="contact-card-icon">
                <img src="/icons/contacts/phone.svg" />
              </div>
              <div class="contact-card-content">
                +372 5841 6216
              </div>
            </a>
          </div>
          <div class="contact-card">
            <a
              href="https://www.google.com/maps/place/J%C3%B5e+3,+10151+Tallinn/@59.4378918,24.7618213,17z/data=!3m1!4b1!4m5!3m4!1s0x4692935e7755ff09:0x23e2b0c6cf71ffcf!8m2!3d59.4378891!4d24.7640101"
              target="_blank"
              class="w-full flex"
            >
              <div class="contact-card-icon">
                <img src="/icons/contacts/location.svg" />
              </div>
              <div class="contact-card-content">
                Jõe 3, Tallinn
              </div>
            </a>
          </div>
          <div class="contact-card">
            <a
              href="https://facebook.com/gewodo"
              target="_blank"
              class="w-full flex"
            >
              <div class="contact-card-icon bg-something">
                <img src="/icons/contacts/facebook.svg" />
              </div>
              <div class="contact-card-content">
                Gewodo
              </div>
            </a>
          </div>
          <div class="contact-card">
            <a
              class="w-full flex"
              href="https://linkedin.com/company/gewodo"
              target="_blank"
            >
              <div class="contact-card-icon">
                <img src="/icons/contacts/linkedin.svg" />
              </div>
              <div class="contact-card-content">
                Gewodo
              </div>
            </a>
          </div>

          <div class="contact-card">
            <a
              href="https://instagram.com/gewodoeesti"
              target="_blank"
              class="w-full flex"
            >
              <div class="contact-card-icon">
                <img src="/icons/contacts/instagram.svg" />
              </div>
              <div class="contact-card-content">
                gewodoeesti
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <faq class="mobile:w-11/12 mobile:mx-auto w-full"></faq>
      </div>
    </div>
  </div>
</template>

<script>
import FAQ from "@/components/public/FAQ";
import ContactForm from "@/components/forms/ContactForm";

export default {
  components: {
    faq: FAQ,
    ContactForm
  }
};
</script>

<style lang="scss">
.contact-card {
  @apply rounded-md py-2 px-2 flex flex-row mb-3 bg-white shadow;
  transition: all ease-out 0.25s;
  .contact-card-icon {
    @apply w-2/12 flex justify-center rounded-md p-2;
  }
  .contact-card-content {
    @apply w-10/12 flex justify-center items-center;
  }
  &:hover {
    @apply bg-green text-white;
    .contact-card-content {
      @apply font-bold;
    }
    .contact-card-icon {
      img {
        filter: invert(99%) sepia(5%) saturate(688%) hue-rotate(197deg)
          brightness(105%) contrast(100%);
      }
    }
  }
}
</style>
